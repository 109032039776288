import { loadAmbassadorWixEcomCartServicesWebHttp, loadCartMapper } from '../dynamicImports'
import { CART_API_BASE_URL, STORES_APP_DEF_ID, WixStoresCartSdkInteraction } from '../constants'
import { WixStoresServiceSdk } from '../WixStoresServiceSdk'

export class CartServiceSdk extends WixStoresServiceSdk {
	async getCurrentCart(): Promise<any> {
		this.fedopsLogger.interactionStarted(WixStoresCartSdkInteraction.GET_CURRENT_CART)
		const { WixEcommerceCartServicesWeb } = await loadAmbassadorWixEcomCartServicesWebHttp()
		const { cartMapperClient } = await loadCartMapper()

		const cartApiFactory = WixEcommerceCartServicesWeb(CART_API_BASE_URL).Carts()
		const cartApi = cartApiFactory(this.getRequestHeaders())

		const res = await cartApi.getCurrentCart({})
		this.fedopsLogger.interactionEnded(WixStoresCartSdkInteraction.GET_CURRENT_CART)
		return cartMapperClient(res.cart as any, this.getInstanceFunc)
	}

	applyCoupon(couponCode: string): Promise<any> {
		this.fedopsLogger.interactionStarted(WixStoresCartSdkInteraction.APPLY_COUPON)
		return this.appsPublicApisUtils.getPublicAPI(STORES_APP_DEF_ID).then(async (api: any) => {
			await api.applyCouponToCart(couponCode)
			this.fedopsLogger.interactionEnded(WixStoresCartSdkInteraction.APPLY_COUPON)
			return this.getCurrentCart()
		})
	}

	removeCoupon(): Promise<any> {
		this.fedopsLogger.interactionStarted(WixStoresCartSdkInteraction.REMOVE_COUPON)
		return this.appsPublicApisUtils.getPublicAPI(STORES_APP_DEF_ID).then(async (api: any) => {
			await api.removeCouponFromCart()
			this.fedopsLogger.interactionEnded(WixStoresCartSdkInteraction.REMOVE_COUPON)
			return this.getCurrentCart()
		})
	}

	updateLineItemQuantity(cartItemId: number, quantity: number): Promise<any> {
		this.fedopsLogger.interactionStarted(WixStoresCartSdkInteraction.UPDATE_LINE_ITEM_QUANTITY)
		return this.appsPublicApisUtils.getPublicAPI(STORES_APP_DEF_ID).then(async (api: any) => {
			await api.updateLineItemQuantityInCart(cartItemId, quantity)
			this.fedopsLogger.interactionEnded(WixStoresCartSdkInteraction.UPDATE_LINE_ITEM_QUANTITY)
			return this.getCurrentCart()
		})
	}

	addProducts(products: Array<any>): Promise<any> {
		this.fedopsLogger.interactionStarted(WixStoresCartSdkInteraction.ADD_PRODUCTS_TO_CART)
		return this.appsPublicApisUtils.getPublicAPI(STORES_APP_DEF_ID).then(async (api: any) => {
			await api.addProductsToCart(products)
			this.fedopsLogger.interactionEnded(WixStoresCartSdkInteraction.ADD_PRODUCTS_TO_CART)
			return this.getCurrentCart()
		})
	}
}
