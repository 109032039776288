import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { clientModuleFetcher, createClientSAC, toClientSACFactoryParamsFrom } from 'thunderbolt-site-assets-client'
import { platformFedopsMetricsReporter } from '@wix/thunderbolt-commons'
import { BootstrapData } from '../types'

export const siteAssetsClientWorkerAdapter = (bootstrapData: BootstrapData, logger: PlatformLogger) => {
	const fetchFn = self.fetch
	const {
		siteAssetsClientInitParams: {
			clientTopology,
			manifests,
			tbElementsManifests,
			siteAssetsClientConfig: { isStagingRequest },
			deviceInfo
		},
		experiments,
		platformEnvData: {
			location: { rawUrl },
			site: {
				mode: { qa }
			}
		}
	} = bootstrapData

	return createClientSAC(
		toClientSACFactoryParamsFrom({
			siteAssets: bootstrapData.siteAssetsClientInitParams,
			env: 'clientWorker',
			deviceInfo,
			qa,
			experiments,
			requestUrl: rawUrl,
			fetchFn,
			isStagingRequest,
			moduleFetcher: clientModuleFetcher(
				fetchFn,
				clientTopology,
				{
					thunderbolt: manifests,
					tbElements: tbElementsManifests
				},
				'webWorker'
			),
			siteAssetsMetricsReporter: platformFedopsMetricsReporter(logger)
		})
	)
}
