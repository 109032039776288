import { BootstrapData } from '../types'
import { EventHandlers } from './types'
import { AppSpecData } from '@wix/thunderbolt-symbols'
import { MasterPageId } from './constants'
import { StaticEventsManager } from './staticEventsManager'

export interface WixCodeViewerAppUtils {
	createWixCodeAppData(
		appData: AppSpecData
	): {
		userCodeMap: Array<{
			url: string
			displayName: string
			id: string
			scriptName: string
		}>
	}
	setStaticEventHandlers(eventHandlers: EventHandlers): void
}

export default function({ bootstrapData, staticEventsManager }: { bootstrapData: BootstrapData; staticEventsManager: StaticEventsManager }) {
	const {
		wixCodeBootstrapData: { wixCodePageIds },
		platformEnvData,
		currentPageId,
		experiments
	} = bootstrapData

	const pagesToRunUserCodeOn = platformEnvData.bi.pageData.isLightbox ? [currentPageId] : [MasterPageId, currentPageId]

	return {
		createWixCodeAppData() {
			return {
				userCodeMap: pagesToRunUserCodeOn
					.filter((pageId) => wixCodePageIds[pageId])
					.map((pageId: string) => ({
						url: wixCodePageIds[pageId],
						displayName: pageId === MasterPageId ? 'site' : `${platformEnvData.site.pageIdToTitle[pageId]} page`,
						id: pageId,
						scriptName: `${pageId}.js`
					})),
				shouldReportUserCodeInteraction: !!experiments['specs.thunderbolt.report_user_interaction_from_user_code_app']
			}
		},
		setStaticEventHandlers: async (eventHandlers: EventHandlers) => {
			staticEventsManager.setStaticEventsCallbacks(eventHandlers)
		}
	}
}
