import _ from 'lodash'

import { ViewerAppSpecData, WidgetNames } from '@wix/thunderbolt-symbols'
import { BootstrapData } from '../types'

export const DATA_BINDING_APP_DEF_ID = 'dataBinding'

export type ClientSpecMapAPI = {
	getViewerScriptUrl(appDefinitionId: string): string | null
	getControllerScript(appDefinitionId: string, widgetId: string): string
	getAppSpecData(appDefinitionId: string): ViewerAppSpecData
	getAppWidgetNames(appDefinitionId: string): Record<string, string>
	isWixCodeInstalled(): boolean
	getWixCodeAppDefinitionId(): string | undefined
	getDataBindingAppDefinitionId(): string
	getStudioAppsAppDefinitionIds(): Array<string>
	isAppOnSite(appDefinitionId: string): boolean
}

export default function({ bootstrapData }: { bootstrapData: BootstrapData }): ClientSpecMapAPI {
	const { appsSpecData, wixCodeBootstrapData, appsUrlData, studioAppsAppDefinitionIds, widgetNames } = bootstrapData
	const wixCodeAppData = wixCodeBootstrapData.wixCodeAppDefinitionId && appsSpecData[wixCodeBootstrapData.wixCodeAppDefinitionId]

	return {
		getViewerScriptUrl(appDefinitionId: string) {
			return _.get(appsUrlData, [appDefinitionId, 'viewerScriptUrl'])
		},
		getControllerScript(appDefinitionId: string, widgetId: string) {
			return _.get(appsUrlData, [appDefinitionId, 'widgets', widgetId, 'controllerUrl'])
		},
		getAppSpecData(appDefinitionId: string): ViewerAppSpecData {
			return appsSpecData[appDefinitionId]
		},
		getAppWidgetNames(appDefinitionId: string): WidgetNames {
			return _.get(widgetNames, [appDefinitionId, 'widgets'], {})
		},
		isWixCodeInstalled() {
			return !!wixCodeAppData
		},
		getWixCodeAppDefinitionId() {
			return wixCodeBootstrapData.wixCodeAppDefinitionId
		},
		getDataBindingAppDefinitionId() {
			return DATA_BINDING_APP_DEF_ID
		},
		getStudioAppsAppDefinitionIds() {
			return studioAppsAppDefinitionIds
		},
		isAppOnSite(appDefinitionId) {
			return !!appsSpecData[appDefinitionId]
		}
	}
}
